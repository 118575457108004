import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable()
export class BackendInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token: string = sessionStorage.getItem('token');
  if (token) {
    request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
  }
  // request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
  // console.log(request.headers)
  return next.handle(request).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        // Handle token expiration here
        this.logout();
      }
      return throwError(error);
    })
  );
  
  }

  private logout() {
    localStorage.removeItem('token');
    window.location.href = '/';
  }
}
