import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './common-component/layout/layout.component';
import { Layers } from 'angular-feather/icons';
import { FeatherModule } from 'angular-feather';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BackendInterceptor } from './backend.interceptor';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

const icons = {
  Layers
};

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    FeatherModule.pick(icons),
    HttpClientModule,
    NgxSpinnerModule.forRoot({ type: 'line-spin-clockwise-fade-rotating' }),
    ToastrModule.forRoot(),
    NgbModule, // ToastrModule added
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass:BackendInterceptor, multi: true },
    DatePipe
  ],
  exports: [
    FeatherModule
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule {}
